import * as types from "./types";
import * as routeNames from "@/router/config";
import router from "@/router/router";

import authService from "@/services/api/authService";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";

export default {
  [types.USER_AUTOLOGIN]: ({ commit, state }) => {
    const token = window.localStorage.getItem(types.GLOBAL_USER_TOKEN);
    if (!token) {
      router.replace({ name: routeNames.LOGIN_ROUTE });
      return;
    }
    if (state.User) {
      return;
    }
    authService
      .post("/load", null, { headers: { Authorization: "Bearer " + token } })
      .then((response) => {
        commit(types.MUTATE_USER_INJECT, response.data.User);
        commit(types.MUTATE_AREAS, response.data.Areas);
        commit(types.MUTATE_USER_GROUPS, response.data.UserGroups);
        commit(types.MUTATE_USER_ROLES, response.data.UserRoles);
        commit(types.MUTATE_LANGUAGES, response.data.Languages);
      })
      .catch(() => {
        notificationServiceBus.showErrorMessage(
          "Informação",
          "Insira as sua credenciais"
        );
        window.localStorage.removeItem(types.GLOBAL_USER_TOKEN);
        commit(types.MUTATE_CLEAR_STORE);
        router.replace({ name: routeNames.LOGIN_ROUTE });
      });
  },
  [types.USER_LOGIN]: ({ commit }, payload) => {
    window.localStorage.setItem(types.GLOBAL_USER_TOKEN, payload.Token);
    commit(types.MUTATE_USER_INJECT, payload.User);
    commit(types.MUTATE_AREAS, payload.Areas);
    commit(types.MUTATE_USER_GROUPS, payload.UserGroups);
    commit(types.MUTATE_USER_ROLES, payload.UserRoles);
    commit(types.MUTATE_LANGUAGES, payload.Languages);
  },
  [types.USER_LOGOUT]: ({ commit }) => {
    const token = window.localStorage.getItem(types.GLOBAL_USER_TOKEN);
    authService
      .post("/logout", null, { headers: { Authorization: "Bearer " + token } })
      .then(() => {
        window.localStorage.removeItem(types.GLOBAL_USER_TOKEN);
        commit(types.MUTATE_CLEAR_STORE);
        router.replace({ name: routeNames.LOGIN_ROUTE });
      })
      .catch(() => {
        notificationServiceBus.showErrorMessage(
          "Informação",
          "Não foi possível efetuar o logout"
        );
      });
  },
};
