/* eslint-disable prettier/prettier */
export const CatalogCategoriesIndexView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/categories/index.vue");
export const CatalogCoursesIndexView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/courses/index.vue");
export const CatalogCoursesDetailView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/courses/detail.vue");
export const CatalogCoursesDetailDashboardView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/courses/detail/dashboard.vue");
export const CatalogCoursesDetailContentsView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/courses/detail/contents.vue");
export const CatalogCoursesDetailMessagesView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/courses/detail/messages.vue");
export const CatalogCoursesDetailCommentsView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/courses/detail/comments.vue");
export const CatalogCoursesDetailInscriptionsView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/courses/detail/inscriptions.vue");
export const CatalogCoursesDetailEditView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/courses/detail/edit.vue");
export const CatalogCoursesClassesView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/courses/detail/classes.vue");
export const CatalogCoursesDetailStatisticsView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/courses/detail/statistics.vue");
export const CatalogCoursesDetailUsersView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/courses/detail/users.vue");
export const CatalogPortfoliosIndexView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/portfolios/index.vue");
export const CatalogPortfoliosDetailView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/portfolios/detail.vue");
export const CatalogPortfoliosEditView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/portfolios/detail/edit.vue");
export const CatalogPortfoliosDetailDashboardView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/portfolios/detail/dashboard.vue");
export const CatalogPortfoliosDetailBasicView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/portfolios/detail/basic.vue");
export const CatalogPortfoliosDetailShortView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/portfolios/detail/short.vue");
export const CatalogPortfoliosDetailMediumView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/portfolios/detail/medium.vue");
export const CatalogPortfoliosDetailLongView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/portfolios/detail/long.vue");
export const CatalogPortfoliosDetailComplementaryView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/portfolios/detail/complementary.vue");
export const CatalogPortfoliosUsersView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/portfolios/detail/users.vue");
export const CatalogCalendarIndexView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/calendar/index.vue");
export const CatalogInscriptionsIndexView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/inscriptions/index.vue");
export const CatalogNoticesIndexView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/notices/index.vue");
export const CatalogSurveysIndexView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/surveys/index.vue");
export const CatalogSurveysDetailView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/surveys/detail.vue");
export const CatalogSurveysDetailDashboardView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/surveys/detail/dashboard.vue");
export const CatalogSurveysQuestionsView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/surveys/detail/questions.vue");
export const CatalogSurveysEditView = () => import( /* webpackChunkName: "catalog-module" */ "@/views/catalog/surveys/detail/edit.vue");
