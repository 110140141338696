<template>
  <v-app>
    <v-content>
      <kendo-notification ref="appNotificationsRef" :position="{ top: 30 }" />
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
import * as types from "@/store/types";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";

export default {
  data() {
    return {
      appNotificationsWidget: null,
    };
  },
  created() {
    notificationServiceBus.$on("showMessage", (data) => {
      if (data.type == "success") {
        this.showSuccessMessage(data.title, data.message);
      } else if (data.type == "error") {
        this.showErrorMessage(data.title, data.message);
      } else if (data.type == "info") {
        this.showInfoMessage(data.title, data.message);
      }
    });
    if (!location.pathname.startsWith("/auth/")) {
      this.$store.dispatch(types.USER_AUTOLOGIN);
    }
  },
  mounted() {
    this.appNotificationsWidget = this.$refs.appNotificationsRef.kendoWidget();
  },
  methods: {
    showSuccessMessage(title, message) {
      this.appNotificationsWidget.show(message, "success");
    },
    showErrorMessage(title, message) {
      this.appNotificationsWidget.show(message, "error");
    },
    showInfoMessage(title, message) {
      this.appNotificationsWidget.show(message, "info");
    },
  },
};
</script>
