export const LOGIN_ROUTE = "login";
export const RECOVER_ROUTE = "recover";
export const LOGOUT_ROUTE = "logout";
export const DASHBOARD_ROUTE = "dashboard";
export const CATEGORIES_ROUTE = "categories";
export const COURSES_ROUTE = "courses";
export const REPORTS_ROUTE = "reports";
export const Gamification_ROUTE = "Gamification";
export const CONFIGURATIONS_ROUTE = "configurations";
export const MYACCOUNT_ROUTE = "myaccount";
