<template>
  <v-dialog width="800" ref="modal" v-model="value" persistent="">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        Adicionar Inscrição
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md text-xs-center>
          <v-layout row wrap>
            <v-flex xs12>
              <course-selector v-model="Instance.IdCourse"></course-selector>
            </v-flex>
            <v-flex xs12>
              <course-class-selector
                v-model="Instance.IdCourseClass"
              ></course-class-selector>
            </v-flex>
            <v-flex>
              <v-textarea
                v-model="Instance.Code"
                :error="$v.Instance.Code.$error"
                @input="$v.Instance.Code.$touch()"
                label="Introduza os Códigos por linha"
                required
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn flat @click="closeModal()">Cancelar </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          flat
          :loading="isLoading"
          @click="confirmAction()"
          :disabled="$v.Instance.$touch() || $v.Instance.$error"
          >Adicionar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CoursesSelector from "@/components/selectors/courses";
import CourseClassesSelector from "@/components/selectors/course-classes";
import { minLength, required } from "vuelidate/lib/validators";
import subscriptionsService from "@/services/api/userCourseSubscriptionsService";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
export default {
  props: ["value"],
  data() {
    return {
      isLoading: false,
      Instance: {},
    };
  },
  components: {
    "course-selector": CoursesSelector,
    "course-class-selector": CourseClassesSelector,
  },
  validations: {
    Instance: {
      Code: {
        required,
        minLength: minLength(3),
      },
      IdCourseClass: {
        required,
      },
    },
  },
  methods: {
    async confirmAction() {
      if (this.isLoading) return false;
      this.isLoading = true;
      await subscriptionsService
        .CreateByCodes(
          this.Instance.Code.split("\n"),
          this.Instance.IdCourse,
          this.Instance.IdCourseClass != null && this.Instance.IdCourseClass > 0
            ? this.Instance.IdCourseClass
            : null
        )
        .then((res) => {
          notificationServiceBus.showInfoMessage(
            "Criação concluida",
            `<p><strong>Efectou a inscrição com successo</strong> ${res.data.UsersInserted.length}</p>
           <p><strong>Incrições ativas</strong> ${res.data.UsersIgnored.length}</p>`
          );
          this.reset();
          this.closeModal();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        })
        .finally(() => (this.isLoading = false));
    },
    closeModal() {
      this.$emit("input", false);
    },
    reset() {
      this.Instance = {};
    },
  },
};
</script>
