import Vue from "vue";
import Vuetify from "vuetify/lib";
import pt from "vuetify/es5/locale/pt";

Vue.use(Vuetify, {
  lang: {
    locales: { pt },
    current: "pt",
  },
  theme: {
    primary: "#00AC46",
    secondary: "#09a9b3",
    accent: "#3782CD",
    error: "#FF5252",
    info: "#90A4AE",
    success: "#4CAF50",
    warning: "#F5A01E"
  },
});
