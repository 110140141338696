import "./styles/app.scss";

import Vue from "vue";
import "./plugins/vuetify";
import Vuelidate from "vuelidate";
import axios from "axios";
import * as kendo from "@progress/kendo-ui";
import "@progress/kendo-theme-material";
import "@progress/kendo-ui/js/messages/kendo.messages.pt-PT.js";
import "@progress/kendo-ui/js/cultures/kendo.culture.pt-PT.js";
import store from "./store/global";
import router from "./router/router";

import AppView from "./App";
import * as settings from "./settings";

Vue.config.productionTip = false;

// App register
axios.defaults.headers.common["APP"] = "Backend";
axios.defaults.headers.get["Accepts"] = "application/json";
Vue.prototype.$http = axios;

import {
  SchedulerInstaller,
  Scheduler,
} from "@progress/kendo-scheduler-vue-wrapper";
import { GridPdfExport } from "@progress/kendo-vue-pdf";
import {
  Calendar,
  DateInput,
  DatePicker,
  DateRangePicker,
  DateTimePicker,
  MultiViewCalendar,
  TimePicker,
  DateinputsInstaller,
} from "@progress/kendo-dateinputs-vue-wrapper";
import {
  Button,
  ButtonGroup,
  ButtonGroupButton,
  ToolBar,
  ToolBarItem,
  ButtonsInstaller,
} from "@progress/kendo-buttons-vue-wrapper";
import { Window, WindowInstaller } from "@progress/kendo-window-vue-wrapper";
import {
  AutoComplete,
  ComboBox,
  DropDownList,
  MultiSelect,
  MultiColumnComboBox,
  MultiColumnComboBoxColumn,
  DropdownsInstaller,
} from "@progress/kendo-dropdowns-vue-wrapper";
import {
  TreeView,
  TreeViewItem,
  TreeViewInstaller,
} from "@progress/kendo-treeview-vue-wrapper";
import {
  Pager,
  ListView,
  ListViewInstaller,
} from "@progress/kendo-listview-vue-wrapper";
import {
  ContextMenu,
  Menu,
  PanelBar,
  Splitter,
  TabStrip,
  LayoutInstaller,
} from "@progress/kendo-layout-vue-wrapper";
import {
  Editor,
  EditorTool,
  EditorInstaller,
} from "@progress/kendo-editor-vue-wrapper";
import {
  MaskedTextBox,
  NumericTextBox,
  ColorPicker,
  Slider,
  RangeSlider,
  InputsInstaller,
} from "@progress/kendo-inputs-vue-wrapper";
import {
  KendoTooltip,
  KendoNotification,
  KendoPopupsInstaller,
} from "@progress/kendo-popups-vue-wrapper";
import { Upload, UploadInstaller } from "@progress/kendo-upload-vue-wrapper";
import {
  DataSource,
  DataSourceInstaller,
} from "@progress/kendo-datasource-vue-wrapper";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
// import { GridInstaller } from "@progress/kendo-grid-vue-wrapper";
import { Dialog, DialogInstaller } from "@progress/kendo-dialog-vue-wrapper";
import {
  Chart,
  Sparkline,
  StockChart,
  ChartInstaller,
} from "@progress/kendo-charts-vue-wrapper";

import AppTitleComponent from "./components/layout/page-title";
import LoadScript from "vue-plugin-load-script";

Vue.component("app-page-title", AppTitleComponent);
Vue.component("Grid", Grid);
Vue.component("grid-pdf-export", GridPdfExport);
Vue.component("GridNoRecords", GridNoRecords);

// Vue.use(GridInstaller);
Vue.use(UploadInstaller);
Vue.use(KendoPopupsInstaller);
Vue.use(InputsInstaller);
Vue.use(EditorInstaller);
Vue.use(LayoutInstaller);
Vue.use(ListViewInstaller);
Vue.use(DateinputsInstaller);
Vue.use(ButtonsInstaller);
Vue.use(WindowInstaller);
Vue.use(DropdownsInstaller);
Vue.use(TreeViewInstaller);
Vue.use(DataSourceInstaller);
Vue.use(DialogInstaller);
Vue.use(ChartInstaller);
Vue.use(SchedulerInstaller);
Vue.use(Vuelidate);
Vue.use(LoadScript);

kendo.pdf.defineFont({
  "DejaVu Sans": "/assets/fonts/dejavu/DejaVuSans.ttf",
  "DejaVu Sans|Bold": "/assets/fonts/dejavu/DejaVuSans-Bold.ttf",
  "DejaVu Sans|Bold|Italic": "/assets/fonts/dejavu/DejaVuSans-Oblique.ttf",
  "DejaVu Sans|Italic": "/assets/fonts/dejavu/DejaVuSans-Oblique.ttf",
  WebComponentsIcons: "/assets/fonts/icons/WebComponentsIcons.ttf",
});
kendo.ui.FilterMenu.fn.options.operators.none = settings.KendoUI.NoneOperators;
kendo.ui.FilterMenu.fn.options.operators.string =
  settings.KendoUI.StringOperators;
kendo.ui.FilterMenu.fn.options.operators.enums = settings.KendoUI.EnumOperators;
kendo.ui.FilterMenu.fn.options.operators.number =
  settings.KendoUI.NumberOperators;
kendo.ui.FilterMenu.fn.options.operators.date = settings.KendoUI.DateOperators;
kendo.cultures["custom"] = settings.KendoUI.Culture;
kendo.culture("pt-PT");

new Vue({
  router,
  store,
  components: {
    Dialog,
    DataSource,
    Upload,
    Window,
    Pager,
    ListView,
    Editor,
    EditorTool,
    AutoComplete,
    ComboBox,
    DropDownList,
    MultiSelect,
    MultiColumnComboBox,
    MultiColumnComboBoxColumn,
    Button,
    ButtonGroup,
    ButtonGroupButton,
    ToolBar,
    ToolBarItem,
    MaskedTextBox,
    NumericTextBox,
    ColorPicker,
    Slider,
    RangeSlider,
    TreeView,
    TreeViewItem,
    Calendar,
    DateInput,
    DatePicker,
    DateRangePicker,
    DateTimePicker,
    MultiViewCalendar,
    TimePicker,
    ContextMenu,
    Menu,
    PanelBar,
    Splitter,
    TabStrip,
    KendoTooltip,
    KendoNotification,
    Chart,
    Sparkline,
    StockChart,
    Scheduler,
    Grid,
  },
  render: (h) => h(AppView),
}).$mount("#app");
