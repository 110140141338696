export const API_URL = process.env.VUE_APP_API;

export const REPORTING_URL = process.env.VUE_APP_REPORTING;
export const REPORTING_KENDO = process.env.VUE_APP_REPORTING_KENDO;
export const REPORTING_SERVICE_URL = process.env.VUE_APP_REPORTING_SERVICE_URL;
export const REPORTING_SERVER_URL = process.env.VUE_APP_REPORTING_SERVER_URL;
export const REPORTING_SERVER_USERNAME = process.env.VUE_APP_REPORTING_SERVER_USERNAME;
export const REPORTING_SERVER_PASSWORD = process.env.VUE_APP_REPORTING_SERVER_PASSWORD;

export const FRONTEND_URL = process.env.VUE_APP_FRONTEND;

export const IS_DEV = !process.env.NODE_ENV.toLowerCase() === "production";

export const DateFormat = "dd-MM-yyyy";

export const DateTimeFormat = "dd-MM-yyyy HH:mm:ss";

export const KendoUI = {
  SimpleRichEditorTools: [
    "bold",
    "italic",
    "underline",
    "insertUnorderedList",
    "insertOrderedList",
    "insertImage",
  ],
  BasicRichEditorTools: [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "subscript",
    "superscript",
    "fontSize",
    "foreColor",
    "backColor",
    "justifyLeft",
    "justifyCenter",
    "justifyRight",
    "justifyFull",
    "insertUnorderedList",
    "insertOrderedList",
    "indent",
    "outdent",
    "insertImage",
    "formatting",
    "cleanFormatting",
    "createLink",
    "unlink",
  ],
  DateOperators: {
    eq: "Igual a",
    gte: "Maior ou igual a",
    gt: "Maior que",
    lte: "Menor ou igual a",
    lt: "Menor que",
  },
  NumberOperators: {
    eq: "Igual a",
    gte: "Maior ou igual a",
    gt: "Maior que",
    lte: "Menor ou igual a",
    lt: "Menor que",
  },
  EnumOperators: {
    eq: "Igual a",
  },
  StringOperators: {
    eq: "Igual a",
    contains: "Contém",
  },
  NoneOperators: {
    eq: "Igual a",
  },
  Culture: {
    name: "pt-PT",
    numberFormat: {
      pattern: ["-n"],
      decimals: 2,
      ",": ".",
      ".": ",",
      groupSize: [3],
      percent: {
        pattern: ["-n %", "n %"],
        decimals: 2,
        ",": ".",
        ".": ",",
        groupSize: [3],
        symbol: "%",
      },
      currency: {
        pattern: ["(n$)", "n$"],
        decimals: 2,
        ",": ".",
        ".": ",",
        groupSize: [3],
        symbol: "€",
      },
    },
    calendars: {
      standard: {
        days: {
          names: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ],
          namesAbbr: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          namesShort: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        },
        months: {
          names: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          namesAbbr: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        AM: ["AM", "am", "AM"],
        PM: ["PM", "pm", "PM"],
        patterns: {
          d: "dd-MM-yyyy",
          D: "dddd, MMMM dd, yyyy",
          F: "dddd, MMMM dd, yyyy h:mm:ss tt",
          g: "dd-MM-yyyy h:mm tt",
          G: "dd-MM-yyyy h:mm:ss tt",
          m: "MMMM dd",
          M: "MMMM dd",
          s: `yyyy'-'MM'-'ddTHH':'mm':'ss'`,
          t: "h:mm tt",
          T: "h:mm:ss tt",
          u: `'yyyy'-'MM'-'dd HH':'mm':'ss'Z''`,
          y: "MMMM, yyyy",
          Y: "MMMM, yyyy",
        },
        firstDay: 0,
      },
    },
  },
};
