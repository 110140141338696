import Vue from "vue";

export const notificationServiceBus = new Vue({
  methods: {
    showSuccessMessage(title, message) {
      this.$emit("showMessage", {
        type: "success",
        title: title,
        message: message,
      });
    },
    showErrorMessage(title, message) {
      this.$emit("showMessage", {
        type: "error",
        title: title,
        message: message,
      });
    },
    showInfoMessage(title, message) {
      this.$emit("showMessage", {
        type: "info",
        title: title,
        message: message,
      });
    },
    showError(axiosResponse) {
      if (axiosResponse && axiosResponse.data && axiosResponse.data.Errors) {
        this.$emit("showMessage", {
          type: "error",
          title: "Operação inválida",
          message: axiosResponse.data.Errors[0],
        });
      }
    },
  },
});
