import * as types from "./types";

export default {
  [types.MUTATE_USER_ROLES]: (state, payload) => {
    state.UserRoles = payload;
  },
  [types.MUTATE_LANGUAGES]: (state, payload) => {
    state.Languages = payload;
  },
  [types.MUTATE_USER_GROUPS]: (state, payload) => {
    state.UserGroups = payload;
  },
  [types.MUTATE_USER_INJECT]: (state, payload) => {
    state.User = payload;
  },
  [types.MUTATE_USER_REMOVE]: (state) => {
    state.User = null;
  },
  [types.MUTATE_CLEAR_STORE]: (state) => {
    state.Areas = null;
    state.UserGroups = null;
    state.UserRoles = null;
    state.User = null;
    state.Languages = null;
  },
  [types.MUTATE_AREAS]: (state, payload) => {
    state.Areas = payload;
  },
};
