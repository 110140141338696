<template>
  <div>
    <v-navigation-drawer v-model="drawer" mini-variant-width="64" :mini-variant="mini" dark app id="Sidebar" width="255" hide-overlay stateless :class="{ collapsed: mini }">
      <v-toolbar flat color="black" height="80" fill-height align-center justify-center>
        <v-icon class="icon-menu" @click="mini = !mini"></v-icon>
      </v-toolbar>
      <div class="sidebar-content">
        <ul class="list-unstyled text-white">
          <li v-for="(menu, index) in areas" :key="`mainmenu-${index}`" :class="{
            active: activePath.startsWith(menu.Route)
          }">
            <a v-if="menu.Childs && menu.Childs.length > 0" href="#" class="size--s14 text-white" @mouseover="openSidebar()" @click.prevent="toggleSubMenu(menu)">
              <i class="size--s20" :class="menu.Icon" />
              <span class="margin--left-20">{{ menu.Title }}</span>
            </a>
            <router-link v-else :to="menu.Route" class="size--s14 text-white">
              <i class="size--s20" :class="menu.Icon" />
              <span class="margin--left-20">{{ menu.Title }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </v-navigation-drawer>
    <transition name="fade" enter-active-class="fadeInLeftBig" leave-active-class="fadeOutLeftBig" mode="out-in">
      <section v-show="isSubmenuOpen" id="Submenu" :class="{ collapsed: mini }">
        <div v-if="subMenu">
          <p class="text-uppercase submenu-title">{{ subMenu.Title }}</p>
          <ul v-if="subMenu.Childs" class="list-unstyled">
            <li v-for="(sub, index) in subMenu.Childs" :key="`submenu-${index}`" :class="{ active: activePath == sub.Route }">
              <router-link :to="sub.Route">
                <i class="mr-3" :class="sub.Icon" />
                <span class="size--s14">{{ sub.Title }}</span>
              </router-link>
            </li>
            <li v-for="(extraMenu, index) in getExtraMenus(subMenu)" :key="index">
              <router-link :to="extraMenu.Route">
                <i class="mr-3" :class="extraMenu.Icon" />
                <span class="size--s14">{{ extraMenu.Title }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </section>
    </transition>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  data () {
    return {
      drawer: true,
      drawerRight: true,
      right: null,
      left: null,
      mini: true,
      isSubmenuOpen: false,
      subMenu: null,
      activePath: null
    };
  },
  computed: {
    ...mapGetters({
      areas: types.GET_AREAS,
      isAdmin: types.IS_USER_ADMIN,
      userRoles: types.GET_USER_ROLES
    })
  },
  watch: {
    $route (to) {
      this.activePath = to.path;
      this.isSubmenuOpen = false;
      this.mini = true;
    },
    isSubmenuOpen: function () {
      document.querySelector("body").style.overflow = this.isSubmenuOpen
        ? "hidden"
        : "auto";
    }
  },
  methods: {
    toggleSubMenu (m) {
      if (this.subMenu == null || this.subMenu.Title != m.Title) {
        this.isSubmenuOpen = true;
      } else {
        this.isSubmenuOpen = !this.isSubmenuOpen;
      }
      this.subMenu = m;
    },
    toggleSidebar () {
      this.mini = !this.mini;
    },
    openSidebar () {
      if (this.mini) {
        this.mini = false;
      }
    },
    closeSidebar () {
      if (this.mini) {
        this.mini = false;
      }
    },
    getExtraMenus (currentMenu) {
      var items = [];
      if (currentMenu.Title === "Utilizadores" && this.isAdmin) {
        for (let index = 0; index < this.userRoles.length; index++) {
          const role = this.userRoles[index];
          items.push({
            Route: `/users?role=${role.IdUserRole}`,
            Icon: "icon-users",
            Title: role.Name
          });
        }
      }
      return items;
    }
  },
  created () {
    this.activePath = this.$route.fullPath;
  }
};
</script>

<style scoped></style>
