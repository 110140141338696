import Vue from "vue";
import VueRouter from "vue-router";
import * as routesNames from "./config";
import * as types from "../store/types";
import LayoutView from "@/views/shared/layout";
import {
  DashboardView,
  HelpView,
  MyAccountIndexView,
  MyAccountSecurityView,
  MyAccountProfileView,
  MyAccountHistoryView,
  MyAccountMessagesView,
} from "./modules/core";
import {
  WorkflowsView,
  WorkflowsMessagesView,
  WorkflowsSegmentsView,
} from "./modules/workflows";
import { SignInView, RecoverView } from "./modules/auth";
import {
  UsersListingView,
  UsersDetailView,
  UsersDetailDashboardView,
  UsersDetailCoursesView,
  UsersDetailReportsView,
  UsersDetailProfileView,
  UsersDetailEditView,
  UsersDetailSecurityView,
  UsersDetailDocumentsView,
  UsersDetailAbsencesView,
  UsersGamificationIndexView,
  UsersGamificationLogsView,
  UsersGamificationBadgesView,
  UsersGamificationDashboardView,
} from "./modules/users";
import {
  ConfigurationsJobLocationsIndexView,
  ConfigurationsJobLocationGroupsIndexView,
  ConfigurationsJobFunctionIndexView,
  ConfigurationsJobCostCentersIndexView,
  ConfigurationsPortfolioCategoriesIndexView,
  ConfigurationsJobDepartmentsIndexView,
  ConfigurationsCourseClassificationsIndexView,
  ConfigurationsUserDocumentCategoriesIndexView,
  ConfigurationsSuppliersIndexView,
  // ConfigurationsJustificationIndexView,
  // ConfigurationsAbsencesIndexView,
  ConfigurationsUserGroupIndexView,
} from "./modules/configurations";
import {
  CatalogCategoriesIndexView,
  CatalogCoursesIndexView,
  CatalogCoursesDetailView,
  CatalogCoursesDetailDashboardView,
  CatalogCoursesDetailMessagesView,
  CatalogCoursesDetailContentsView,
  CatalogCoursesDetailInscriptionsView,
  CatalogCoursesDetailStatisticsView,
  CatalogCoursesDetailCommentsView,
  CatalogCoursesDetailUsersView,
  CatalogCoursesDetailEditView,
  CatalogCoursesClassesView,
  CatalogPortfoliosIndexView,
  CatalogPortfoliosDetailView,
  CatalogPortfoliosEditView,
  CatalogPortfoliosDetailDashboardView,
  CatalogPortfoliosDetailBasicView,
  CatalogPortfoliosDetailShortView,
  CatalogPortfoliosDetailMediumView,
  CatalogPortfoliosDetailLongView,
  CatalogPortfoliosDetailComplementaryView,
  CatalogPortfoliosUsersView,
  CatalogCalendarIndexView,
  CatalogInscriptionsIndexView,
  CatalogNoticesIndexView,
  CatalogSurveysIndexView,
  CatalogSurveysDetailView,
  CatalogSurveysDetailDashboardView,
  CatalogSurveysQuestionsView,
  CatalogSurveysEditView,
} from "./modules/catalog";
import {
  ReportingIndexView,
  ReportingFormationView,
  ReportingHoursFormation,
  ReportingUniqueReport,
  ReportingDeclarationFormation,
} from "./modules/reporting";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: LayoutView,
    beforeEnter(to, from, next) {
      if (window.localStorage.getItem(types.GLOBAL_USER_TOKEN)) {
        next();
      } else {
        next("/auth/signin");
      }
    },
    children: [
      {
        path: "",
        component: DashboardView,
      },
      {
        name: routesNames.DASHBOARD_ROUTE,
        path: "/dashboard",
        component: DashboardView,
      },
      {
        path: "/help",
        component: HelpView,
      },
      {
        path: "/workflows",
        component: WorkflowsView,
      },
      {
        path: "/workflows/messages",
        component: WorkflowsMessagesView,
      },
      {
        path: "/workflows/segments",
        component: WorkflowsSegmentsView,
      },
      // Categories
      {
        name: routesNames.CATEGORIES_ROUTE,
        path: "/catalog/categories",
        component: CatalogCategoriesIndexView,
      },
      // Inscriptions
      {
        path: "/catalog/inscriptions",
        component: CatalogInscriptionsIndexView,
      },
      //Announcements
      {
        path: "/catalog/notices",
        component: CatalogNoticesIndexView,
      },
      {
        path: "/catalog/surveys",
        component: CatalogSurveysIndexView,
      },
      {
        path: "/catalog/surveys/:idsurvey",
        component: CatalogSurveysDetailView,
        props: true,
        children: [
          {
            path: "",
            redirect: "dashboard",
          },
          {
            path: "questions",
            component: CatalogSurveysQuestionsView,
          },
          {
            path: "edit",
            component: CatalogSurveysEditView,
          },
          {
            path: "dashboard",
            component: CatalogSurveysDetailDashboardView,
          },
        ],
      },
      //Gamification
      {
        path: "/catalog/gamification",
        component: UsersGamificationIndexView,
        props: true,
        children: [
          {
            path: "",
            redirect: "dashboard",
          },
          {
            path: "badges",
            component: UsersGamificationBadgesView,
          },
          {
            path: "dashboard",
            component: UsersGamificationDashboardView,
          },
          {
            path: "logs",
            component: UsersGamificationLogsView,
          },
        ],
      },
      // Portfolios
      {
        path: "/catalog/portfolios",
        component: CatalogPortfoliosIndexView,
      },
      {
        path: "/catalog/portfolios/:idPortfolio",
        component: CatalogPortfoliosDetailView,
        props: true,
        children: [
          {
            path: "",
            redirect: "dashboard",
          },
          {
            path: "edit",
            component: CatalogPortfoliosEditView,
          },
          {
            path: "dashboard",
            component: CatalogPortfoliosDetailDashboardView,
          },
          {
            path: "basic",
            component: CatalogPortfoliosDetailBasicView,
          },
          {
            path: "short",
            component: CatalogPortfoliosDetailShortView,
          },
          {
            path: "medium",
            component: CatalogPortfoliosDetailMediumView,
          },
          {
            path: "long",
            component: CatalogPortfoliosDetailLongView,
          },
          {
            path: "complementary",
            component: CatalogPortfoliosDetailComplementaryView,
          },
          {
            path: "users",
            component: CatalogPortfoliosUsersView,
          },
        ],
      },
      // Calendar
      {
        path: "/catalog/calendar",
        component: CatalogCalendarIndexView,
      },
      // Courses
      {
        name: routesNames.COURSES_ROUTE,
        path: "/catalog/courses",
        component: CatalogCoursesIndexView,
      },
      {
        path: "/catalog/courses/:idCourse",
        component: CatalogCoursesDetailView,
        props: true,
        children: [
          {
            path: "",
            redirect: "dashboard",
          },
          {
            path: "dashboard",
            component: CatalogCoursesDetailDashboardView,
          },
          {
            path: "contents",
            component: CatalogCoursesDetailContentsView,
          },
          {
            path: "inscriptions",
            component: CatalogCoursesDetailInscriptionsView,
          },
          {
            path: "messages",
            component: CatalogCoursesDetailMessagesView,
          },
          {
            path: "comments",
            component: CatalogCoursesDetailCommentsView,
          },
          {
            path: "classes",
            component: CatalogCoursesClassesView,
          },
          {
            path: "edit",
            component: CatalogCoursesDetailEditView,
          },
          {
            path: "statistics",
            component: CatalogCoursesDetailStatisticsView,
          },
          {
            path: "users",
            component: CatalogCoursesDetailUsersView,
          },
        ],
      },
      // Users
      {
        path: "/users",
        component: UsersListingView,
      },
      {
        path: "/users/:idUser",
        component: UsersDetailView,
        props: true,
        children: [
          {
            path: "",
            redirect: "dashboard",
          },
          {
            path: "dashboard",
            component: UsersDetailDashboardView,
          },
          {
            path: "courses",
            component: UsersDetailCoursesView,
          },
          {
            path: "reports",
            component: UsersDetailReportsView,
          },
          {
            path: "profile",
            component: UsersDetailProfileView,
          },
          {
            path: "edit",
            component: UsersDetailEditView,
          },
          {
            path: "security",
            component: UsersDetailSecurityView,
          },
          {
            path: "documents",
            component: UsersDetailDocumentsView,
          },
          {
            path: "absences",
            component: UsersDetailAbsencesView,
          },
        ],
      },
      // MyAccount
      {
        path: "/myaccount",
        component: MyAccountIndexView,
        props: true,
        children: [
          {
            path: "",
            redirect: "profile",
          },
          {
            path: "profile",
            component: MyAccountProfileView,
            name: routesNames.MYACCOUNT_ROUTE,
          },
          {
            path: "history",
            component: MyAccountHistoryView,
          },
          {
            path: "security",
            component: MyAccountSecurityView,
          },
          {
            path: "messages",
            component: MyAccountMessagesView,
          },
        ],
      },

      // Reporting
      {
        path: "/reports",
        component: ReportingIndexView,
      },
      {
        path: "/reports/declaration",
        component: ReportingDeclarationFormation,
      },
      {
        path: "/reports/unique-report",
        component: ReportingUniqueReport,
      },
      {
        path: "/reports/formation-by-modules",
        component: ReportingFormationView,
      },
      {
        path: "/reports/formation-by-hours",
        component: ReportingHoursFormation,
      },

      // Configurations
      {
        name: routesNames.CONFIGURATIONS_ROUTE,
        path: "/configurations",
        component: ConfigurationsJobLocationsIndexView,
      },
      {
        path: "/configurations/locations",
        component: ConfigurationsJobLocationsIndexView,
      },
      {
        path: "/configurations/locations-groups",
        component: ConfigurationsJobLocationGroupsIndexView,
      },
      {
        path: "/configurations/functions",
        component: ConfigurationsJobFunctionIndexView,
      },
      {
        path: "/configurations/user-groups",
        component: ConfigurationsUserGroupIndexView,
      },
      {
        path: "/configurations/cost-centers",
        component: ConfigurationsJobCostCentersIndexView,
      },
      {
        path: "/configurations/portfolio-categories",
        component: ConfigurationsPortfolioCategoriesIndexView,
      },
      {
        path: "/configurations/departments",
        component: ConfigurationsJobDepartmentsIndexView,
      },
      {
        path: "/configurations/course-classifications",
        component: ConfigurationsCourseClassificationsIndexView,
      },
      {
        path: "/configurations/user-documents-categories",
        component: ConfigurationsUserDocumentCategoriesIndexView,
      },
      {
        path: "/configurations/suppliers",
        component: ConfigurationsSuppliersIndexView,
      },
      // {
      //   path: "/configurations/justifications",
      //   component: ConfigurationsJustificationIndexView
      // },
      // {
      //   path: "/configurations/absences",
      //   component: ConfigurationsAbsencesIndexView
      // }
    ],
  },
  {
    name: routesNames.LOGIN_ROUTE,
    path: "/auth/signin",
    component: SignInView,
    beforeEnter(to, from, next) {
      if (window.localStorage.getItem(types.GLOBAL_USER_TOKEN)) {
        next("/dashboard");
      } else {
        next();
      }
    },
  },
  {
    name: routesNames.RECOVER_ROUTE,
    path: "/auth/recover",
    component: RecoverView,
  },
  {
    path: "*",
    redirect: "/dashboard",
  },
];

export default new VueRouter({
  mode: "history",
  routes,
});
